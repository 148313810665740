import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"

const FormSubmission = () => {
  return (
    <Layout>
      <div>This is the form submission page.</div>
    </Layout>
  )
}

export default FormSubmission
